import { Injectable, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

/**
 * Simple service providing the current route as observable
 */
@Injectable({
  providedIn: 'root'
})
export class CurrentRouteService {
  private router = inject(Router);


  public currentRoute$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

  constructor() {

    // monitor the router
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute$.next(event.urlAfterRedirects);
      }
    });
  }
}